import { useEffect, useMemo, useState } from "react";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import FeatureContentWrapper from "../../../components/FeatureContentWrapper";
import FeatureTitle from "../../../components/FeatureTitle";
import {
  CheckCircleIcon,
  ExclamationIcon,
  InfoIcon,
} from "../../../components/Icons";
import RefreshIcon from "../../../components/Icons/RefreshIcon";
import Typography from "../../../components/Typography";
import useRestaurantsOutletContext from "../../../context/restaurants/useRestaurantsOutletContext";
import useAvailableMenus from "../../../hooks/menus/useAvailableMenus";
import useMenu from "../../../hooks/menus/useMenu";
import RestaurantContent from "../components/RestaurantContent";
import MenuPreviewItem from "./components/MenuPreviewItem";
import MenuLiveStatus from "./components/MenuLiveStatus";
import countMenuErrors from "./utils/countMenuErrors";
import MenuManagementStart from "./components/MenuManagementStart";
import usePublishMenu from "../../../hooks/menus/usePublishMenu";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import { AVAILABLE_MENUS_QUERY_KEY } from "./constants";
import ExternalLink from "../../../components/ExternalLink";
import LaunchIcon from "../../../components/Icons/LauchIcon";
import { Helmet } from "react-helmet-async";
import useOrgId from "../../../hooks/organizations/useOrgId";
import { MenuCurrencyProvider } from "./context/MenuCurrencyContext";
import { MenuOrderTypeProvider } from "./context/MenuOrderTypeContext";

const MenuManagement = () => {
  const queryClient = useQueryClient();
  const { site, store, menu } = useRestaurantsOutletContext();
  const orgId = useOrgId();

  const menuId = !isNaN(parseInt(menu, 10)) ? parseInt(menu, 10) : undefined;

  const {
    data: selectedMenu,
    refetch: refetchMenu,
    isFetching: isFetchingMenu,
  } = useMenu(store, menuId);

  const { data } = useAvailableMenus(store);

  const menuInfo = useMemo(
    () => (data ? data.find((d) => d.menuId === menuId) : null),
    [data, menuId]
  );

  const errorCount = useMemo(() => {
    if (!selectedMenu) {
      return 0;
    }
    return countMenuErrors(selectedMenu);
  }, [selectedMenu]);

  const onCheckForErrors = () => {
    return refetchMenu();
  };

  const {
    mutateAsync: publishMenu,
    isLoading: isPublishingMenu,
    error: publishMenuError,
  } = usePublishMenu();

  const [menuPublished, setMenuPublised] = useState(false);
  const [menuActivated, setMenuActivated] = useState(false);

  useEffect(() => {
    setMenuPublised(false);
    setMenuActivated(false);
  }, [menuId, isFetchingMenu]);

  const onPublishMenu = () => {
    return (
      menuId &&
      publishMenu({
        storeId: store,
        menuId: menuId,
      })
        .then(() => {
          menuInfo?.isSet ? setMenuPublised(true) : setMenuActivated(true);

          queryClient.invalidateQueries([
            AVAILABLE_MENUS_QUERY_KEY,
            orgId,
            store,
          ]);

          const timer = setTimeout(() => {
            setMenuPublised(false);
            setMenuActivated(false);
            clearTimeout(timer);
          }, 2000);
        })
        .catch(() => {
          setMenuPublised(false);
          setMenuActivated(false);
        })
    );
  };

  const _publishMenuError = publishMenuError
    ? (publishMenuError as AxiosError)
    : null;

  if (site === "" || store === "" || !menu) {
    return (
      <FeatureContentWrapper>
        <MenuManagementStart />
      </FeatureContentWrapper>
    );
  }

  return (
    <RestaurantContent>
      <Helmet>
        <title>Connect - Sites - Menu management</title>
      </Helmet>
      <div className="flex flex-col">
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="mb-7">
            <FeatureTitle className="mb-7">Menu Management</FeatureTitle>
            <strong>Menu Title:</strong>{" "}
            <span data-testid="menu-name">{menuInfo?.menuName}</span>
            {selectedMenu && (
              <div className="mt-4 flex items-center">
                <InfoIcon className="h-4 w-4 mr-1" />
                <Typography as="caption">
                  Product price should{" "}
                  {selectedMenu.priceExcludesTax ? (
                    <strong>EXCLUDE</strong>
                  ) : (
                    <strong>INCLUDE</strong>
                  )}{" "}
                  tax.
                </Typography>
              </div>
            )}
          </div>
          <div className="mb-7 flex">
            <SecondaryButton
              disabled={isFetchingMenu}
              onClick={onCheckForErrors}
              className="w-full lg:w-auto mr-2"
            >
              {isFetchingMenu ? (
                <span className="flex items-center">
                  <RefreshIcon className="w-5 h-5 mr-1" viewBox="0 0 24 24" />{" "}
                  Checking...
                </span>
              ) : (
                <span>Check for errors</span>
              )}
            </SecondaryButton>
            {menuInfo?.isSet && !menuPublished && !menuActivated ? (
              <PrimaryButton
                disabled={errorCount > 0 || isPublishingMenu || isFetchingMenu}
                className="w-full lg:w-auto"
                onClick={onPublishMenu}
              >
                {!isPublishingMenu ? "Publish Changes" : "Publishing..."}
              </PrimaryButton>
            ) : null}

            {!menuInfo?.isSet && !menuActivated && !menuPublished ? (
              <PrimaryButton
                disabled={errorCount > 0 || isPublishingMenu || isFetchingMenu}
                className="w-full lg:w-auto"
                onClick={onPublishMenu}
              >
                {!isPublishingMenu ? "Activate Menu" : "Activating..."}
              </PrimaryButton>
            ) : null}

            {menuPublished && (
              <div className="inline-flex items-center px-4 py-3 h-fit rounded-lg border border-green-500 bg-green-50 text-green-500">
                <CheckCircleIcon className="w-5 h-5 mr-2" viewBox="2 2 20 20" />
                <span>Published!</span>
              </div>
            )}
            {menuActivated && (
              <div className="inline-flex items-center px-4 py-3 h-fit rounded-lg border border-green-500 bg-green-50 text-green-500">
                <CheckCircleIcon className="w-5 h-5 mr-2" viewBox="2 2 20 20" />
                <span>Activated!</span>
              </div>
            )}
          </div>
        </div>

        {_publishMenuError ? (
          <div className="p-3 bg-red-50 text-red-500 border border-red-500 rounded-lg flex items-center">
            <ExclamationIcon className="w-6 h-6 mr-2" />
            <Typography as="body-2">
              {menuInfo?.isSet
                ? "Menu could not be published. Try again."
                : "Menu could not be activated. Try again."}
            </Typography>
          </div>
        ) : null}
      </div>

      {isFetchingMenu ? null : (
        <div className="flex justify-center bg-gray-50 lg:py-10">
          <div className="w-[418px] bg-white rounded-lg py-0 lg:py-8 px-0 lg:px-7">
            <div className="-mx-4 sm:mx-0 py-4 px-4  bg-gray-50 border-b border-gray-300">
              <div className="bg-gray-50 mb-2">
                <div className="flex justify-between items-center ">
                  <Typography as="body-1" className="font-bold">
                    Menu Preview
                  </Typography>
                  <div>
                    {menuInfo && <MenuLiveStatus live={menuInfo?.isSet} />}
                  </div>
                </div>
              </div>
              {errorCount ? (
                <div className="bg-red-50 border border-red-500 text-red-500 p-3 rounded-lg flex items-center">
                  <div className="mr-2">
                    <ExclamationIcon className="w-6 h-6" viewBox="2 2 24 24" />
                  </div>
                  <div>
                    <Typography as="body-2" className="text-red-500">
                      {errorCount} error{errorCount > 1 && "s"} found. Please
                      review and fix the errors in Centegra.
                    </Typography>
                  </div>
                </div>
              ) : (
                <div className="bg-green-50 border border-green-500 text-green-500 p-3 rounded-lg flex items-center">
                  <div className="mr-2">
                    <CheckCircleIcon className="w-6 h-6" viewBox="2 2 24 24" />
                  </div>
                  <div>
                    <Typography as="body-2" className="text-green-500">
                      {menuInfo?.isSet
                        ? "Menu has no errors and can now be published."
                        : "Menu has no errors and can now be activated."}
                    </Typography>
                  </div>
                </div>
              )}
              {selectedMenu?.deeplink && (
                <div className="mt-3">
                  <ExternalLink url={selectedMenu.deeplink}>
                    <LaunchIcon className="w-6 h-6" />
                    <span className="ml-2 font-normal">
                      View menu in Centegra
                    </span>
                  </ExternalLink>
                </div>
              )}
            </div>
            <div className="py-4 px-0 lg:px-4">
              {selectedMenu ? (
                <MenuCurrencyProvider currency={selectedMenu.currency}>
                  <MenuOrderTypeProvider orderType={selectedMenu.orderType}>
                    {selectedMenu.items.map((item) => (
                      <MenuPreviewItem key={item.productId} item={item} />
                    ))}
                  </MenuOrderTypeProvider>
                </MenuCurrencyProvider>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </RestaurantContent>
  );
};

export default MenuManagement;
