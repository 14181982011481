export const API_BASE_URL = window.API_BASE_URL || "http://localhost";

const API_V2 = "/v2";
const API_V3 = "/v3";

export const CONNECT_API_BASE_URL = `/connect`;

export const ORDERS_TODAY_API_ENDPOINT = `${CONNECT_API_BASE_URL}/transaction/store/%(storeId)s/refund/%(filter)s`;

export const PARTNERS_API_ENDPOINT = `${CONNECT_API_BASE_URL}/partner`;

export const PARTNER_RESTAURANTS_API_ENDPOINT = `${CONNECT_API_BASE_URL}/restaurant/partner/%(partnerId)s`;

const RESTAURANTS_API_ENDPOINT = `${CONNECT_API_BASE_URL}/restaurant`;
export const RESTAURANT_UPDATE_HOURS_API_ENDPOINT = `${RESTAURANTS_API_ENDPOINT}/%(restaurantId)s/hours`;
export const RESTAURANT_UPDATE_ADVANCED_ORDERING_STATUS_API_ENDPOINT = `${RESTAURANTS_API_ENDPOINT}/%(restaurantId)s/advancedorder/active`;
export const RESTAURANT_UPDATE_SPECIAL_INSTRUCTIONS_API_ENDPOINT = `${CONNECT_API_BASE_URL}/restaurant/%(restaurantId)s/itemNotes`;

export const RESTAURANT_CAPACITY_API_ENDPOINT = `${CONNECT_API_BASE_URL}/capacity/restaurant/%(restaurantId)s`;

export const ORDER_REFUND_API_ENDPOINT = `${CONNECT_API_BASE_URL}/payment/refund/store/%(storeId)s/order/%(orderId)s/channel/%(channelId)s`;
export const ORDER_DETAILS_API_ENDPOINT = `${CONNECT_API_BASE_URL}/transaction/store/%(storeId)s/order/%(orderId)s/channel/%(channelId)s`;
export const ORDER_REPORT_ISSUE_API_ENDPOINT = `${ORDER_DETAILS_API_ENDPOINT}/issue`;

export const PARTNER_DISCOUNTS_API_ENDPOINT = `${CONNECT_API_BASE_URL}/discount`;
export const PARTNER_STANDARD_DISCOUNTS_API_ENDPOINT = `${PARTNER_DISCOUNTS_API_ENDPOINT}/standard`;

export const IDENTITY_API_ENDPOINT = `${API_V2}${CONNECT_API_BASE_URL}/identity`;
export const IDENTITY_ACTIVATE_API_ENDPOINT = `${CONNECT_API_BASE_URL}/user/activate`;

export const ONBOARD_PARTNERS_API_ENDPOINT = `${API_V2}${CONNECT_API_BASE_URL}/onboard/partner`;
export const ONBOARD_PARTNER_API_ENDPOINT = `${API_V2}${CONNECT_API_BASE_URL}/onboard/partner`;
export const ONBOARD_PARTNER_RESTAURANTS_API_ENDPOINT = `${CONNECT_API_BASE_URL}/onboard/restaurant`;
export const ONBOARD_PARTNER_RESTAURANTS_TILLS_API_ENDPOINT = `${CONNECT_API_BASE_URL}/onboard/till`;

export const CONNECT_USERS_API_ENDPOINT = `${API_V2}${CONNECT_API_BASE_URL}/user`;
export const CONNECT_USER_INVITATIONS_API_ENDPOINT = `${CONNECT_API_BASE_URL}/user/invite`;
export const CONNECT_ROLES_API_ENDPOINT = `${CONNECT_API_BASE_URL}/user/role`;
export const CONNECT_UPDATE_USER_API_ENDPOINT = `${CONNECT_API_BASE_URL}/user/%(auth0UserId)s`;
export const CONNECT_DELETE_USER_API_ENDPOINT = `${CONNECT_API_BASE_URL}/user/%(auth0UserId)s`;
export const CONNECT_DELETE_INVITATION_API_ENDPOINT = `${CONNECT_API_BASE_URL}/user/invite/%(auth0InvitationId)s`;

export const PARTNER_FEATURE_MANAGEMENT_API_ENDPOINT = `${CONNECT_API_BASE_URL}/partner/%(partnerId)s`;
export const PARTNER_FEATURE_UPDATE_DISCOUNTS_API_ENDPOINT = `${PARTNER_FEATURE_MANAGEMENT_API_ENDPOINT}/discount`;
export const PARTNER_FEATURE_UPDATE_ROUNDUPS_API_ENDPOINT = `${PARTNER_FEATURE_MANAGEMENT_API_ENDPOINT}/roundup`;
export const PARTNER_FEATURE_CREATE_ROUNDUPS_API_ENDPOINT = `${PARTNER_FEATURE_MANAGEMENT_API_ENDPOINT}/roundup`;

export const DIGITAL_ORDER_MANAGEMENT_SETUP_RESTAURANT_API_ENDPOINT = `${CONNECT_API_BASE_URL}/onboard/partner/%(partnerId)s/restaurant`;
export const DIGITAL_ORDER_MANAGEMENT_UPDATE_RESTAURANT_API_ENDPOINT = `${CONNECT_API_BASE_URL}/onboard/partner/%(partnerId)s/restaurant/%(restaurantId)s`;

export const CREATE_MOBILE_TILL_API_ENDPOINT = `${CONNECT_API_BASE_URL}/onboard/restaurant/till`;

export const STORE_AVAILABLE_MENUS_API_ENDPOINT = `${CONNECT_API_BASE_URL}/menu/restaurant/%(restaurantId)s`;
export const STORE_MENU_API_ENDPOINT = `${API_V3}${CONNECT_API_BASE_URL}/menu/%(menuId)s/store/%(storeId)s`;
export const STORE_PUBLISH_MENU_API_ENDPOINT = `${API_V3}${CONNECT_API_BASE_URL}/menu/%(menuId)s/restaurant/%(storeId)s`;

export const STORE_PRODUCTION_SUSPENSIONS_API_ENDPOINT = `${CONNECT_API_BASE_URL}/menu/restaurant/%(restaurantId)s/product`;
export const STORE_PRODUCT_SUSPENSIONS_SUSPEND_PRODUCT_API_ENDPOINT = `${STORE_PRODUCTION_SUSPENSIONS_API_ENDPOINT}/%(productId)s`;

export const STORE_API_ENDPOINT = `${CONNECT_API_BASE_URL}/store/%(storeId)s`;
export const STORE_UPDATE_ONSITE_ORDERING_API_ENDPOINT = `${CONNECT_API_BASE_URL}/restaurant/%(restaurantId)s/tablemanagement`;

export const STORE_UPDATE_MOBILE_ORDERING_API_ENDPOINT = `${CONNECT_API_BASE_URL}/restaurant/%(restaurantId)s/activeMobile`;
export const STORE_UPDATE_KIOSK_ORDERING_API_ENDPOINT = `${CONNECT_API_BASE_URL}/restaurant/%(restaurantId)s/activeKiosk`;

export const REGION_SITES_API_ENDPOINT = `${CONNECT_API_BASE_URL}/region/%(regionId)s/sites`;
export const REGION_ADD_API_ENDPOINT = `${CONNECT_API_BASE_URL}/onboard/region`;
export const REGION_UPDATE_API_ENDPOINT = `${CONNECT_API_BASE_URL}/onboard/region/%(regionId)s`;
export const REGION_DELETE_API_ENDPOINT = `${CONNECT_API_BASE_URL}/onboard/region`;

export const USER_SITES_API_ENDPOINT = `${CONNECT_API_BASE_URL}/site`;
export const USER_REGIONS_API_ENDPOINT = `${CONNECT_API_BASE_URL}/region`;

export const ORGANIZATION_REGIONS_API_ENDPOINT = `${CONNECT_API_BASE_URL}/onboard/region`;

export const ORGANIZATION_SITE_API_ENDPOINT = `${CONNECT_API_BASE_URL}/site/%(siteId)s`;
export const ORGANIZATION_SITE_PAYMENT_CONFIG_API_ENDPOINT = `${ORGANIZATION_SITE_API_ENDPOINT}/paymentConfig`;

export const ORGANIZATION_UPDATE_ACTIVE_SITE_API_ENDPOINT = `${ORGANIZATION_SITE_API_ENDPOINT}`;
export const ORGANIZATION_UPDATE_SITE_PAYMENT_CONFIG_API_ENDPOINT = `${ORGANIZATION_SITE_API_ENDPOINT}/paymentConfig`;
export const ORGANIZATION_CREATE_SITE_PAYMENT_CONFIG_API_ENDPOINT = `${ORGANIZATION_SITE_API_ENDPOINT}/paymentConfig`;

export const ORGANIZATION_ONBOARD_SITE_API_ENDPOINT = `${API_V3}${CONNECT_API_BASE_URL}/onboard/partner`;

export const ORGANIZATION_PAYMENT_PROVIDERS_API_ENDPOINT = `${CONNECT_API_BASE_URL}/payment/paymentGateway`;
export const ORGANIZATION_PAYMENT_GATEWAY_FIELDS_API_ENDPOINT = `${CONNECT_API_BASE_URL}/payment/gatewayFieldDefinitions`;
export const ORGANIZATION_PAYMENT_CURRENCIES_API_ENDPOINT = `${CONNECT_API_BASE_URL}/payment/currency`;

export const ORGANIZATION_STORE_API_ENDPOINT = `${CONNECT_API_BASE_URL}/onboard/site/%(siteId)s/store`;
export const ORGANIZATION_STORE_UPDATE_API_ENDPOINT = `${CONNECT_API_BASE_URL}/onboard/store/%(storeId)s`;

export const ORGANIZATION_STORE_MOBILE_API_ENDPOINT = `${CONNECT_API_BASE_URL}/onboard/store/%(storeId)s/mobile`;
export const ORGANIZATION_STORE_KIOSK_API_ENDPOINT = `${CONNECT_API_BASE_URL}/onboard/store/%(storeId)s/kiosk`;
