export interface AvailableMenuInterface {
  menuName: string;
  menuId: number;
  isSet: boolean;
  availability: {
    dayOfWeek: number;
    startTime: string;
    endTime: string;
  }[];
}

export const DineInPriceType = "DineIn";
export const TakeOutPriceType = "TakeOut";
export const BothPriceType = "Both";

export type OrderPriceType =
  | typeof DineInPriceType
  | typeof TakeOutPriceType
  | typeof BothPriceType;

export interface MenuInterface {
  id: number;
  deeplink: string;
  currency: string;
  //errors: [] // for future when BE fills out these errors

  orderType: OrderPriceType;
  priceExcludesTax: boolean;

  items: MenuItemInterface[];
}

export interface MenuItemInterface {
  productId: number;
  name: string | null;
  deeplink: string;
  imageUrl: string | null;
  description: string | null;

  prices: {
    price: number | null;
    type: OrderPriceType;
    pricingNet: boolean;
    taxRate: number | null;
  }[];

  errors: MenuItemErrorInterface[];

  modifierGroup: MenuItemModifierGroupInterface[];
}

export interface MenuItemErrorInterface {
  productId: number;
  property: string;
  errorCode: string;
  message: string;
}

export interface MenuItemModifierGroupInterface {
  name: string;
  modifiers: MenuItemModifierGroupModifierInterface[];
  errors: MenuItemModifierGroupErrorInterface[];
}

export interface MenuItemModifierGroupErrorInterface {
  property: string;
  errorCode: string;
  message: string;
}

export interface MenuItemModifierGroupModifierInterface {
  item: {
    productId: number;
    name: string;
    deeplink: string;
    imageUrl: string | null;
    prices: {
      price: number | null;
      type: OrderPriceType;
      pricingNet: boolean;
      taxRate: number | null;
    }[];
  };

  errors: MenuItemErrorInterface[];
}
